@import url('./override.css');
@import url('../components/assignModal/assignModalstyle.css');
/* .ant-menu-title-content {
  padding-left: 0px;
} */
.ant-layout-sider-trigger {
  position: static !important;
  /* background-color: red !important; */
}

span.anticon {
  font-size: 16px !important;
}
span.anticon.anticon-question-circle {
  font-size: 12px !important;
}
span.anticon.anticon-close {
  font-size: 10px !important;
}

.no-wrap {
  text-wrap: nowrap;
}

.statistic-card .ant-card-body {
  padding: 12px 24px 6px;
  height: 120px;
  /* cursor: pointer; */
}

.statistic-card .ant-card-body .ant-statistic-title {
  height: 48px;
}
.us-map-state-selection {
  height: 34px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
}

.ant-card.agent-actionable-table .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-y: auto; */
  height: 630px;
}

.ant-card.agent-actionable-table .ant-card-body::before,
.ant-card.agent-actionable-table .ant-card-body::after {
  content: unset;
}

.ant-table-column-sorter-inner .anticon-caret-up,
.ant-table-column-sorter-inner .anticon-caret-down {
  font-size: 12px !important;
}

.reset-icon-size span.anticon {
  font-size: 12px !important;
}
.reset-icon-size.font-14 span.anticon {
  font-size: 14px !important;
}
.reset-icon-size.font-16 span.anticon {
  font-size: 16px !important;
}

.tooltip-color {
  color: rgba(0, 0, 0, 0.45) !important;
}

.state-groups-card.ant-card .ant-card-body {
  padding: 18px 24px 6px !important;
}

.ml-auto {
  margin-left: auto;
}

.invite-downline-svg {
  padding: 6px 9px !important;
}

.bg-collapsible-container-hide.ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 !important;
}

.bg-collapsible-container-hide.ant-collapse .ant-collapse-content {
  border-top: unset !important;
}

.important-action-button.ant-tooltip-disabled-compatible-wrapper,
.seconday-button.ant-tooltip-disabled-compatible-wrapper {
  background-color: unset !important;
}
.disable-rts.ant-tooltip-disabled-compatible-wrapper {
  background-color: unset !important;
}

.mt-24 {
  margin-top: 24px;
}

.hide-antd-tour-footer .ant-tour-footer {
  display: none !important;
  padding-bottom: 24px !important;
}

.hide-antd-prev.ant-tour .ant-tour-inner .ant-tour-footer {
  padding: 8px 24px 24px !important;
}

.demo-tour.ant-tour .ant-tour-inner .ant-tour-header {
  padding: 16px !important;
}

.demo-tour.ant-tour .ant-tour-inner {
  padding: 0 12px !important;
}

.important-action-button.ant-tooltip-disabled-compatible-wrapper button,
.important-action-button.ant-btn-primary:disabled {
  background-color: var(--important-color) !important;
  border-color: var(--important-color) !important;
}

.important-action-button.ant-tooltip-disabled-compatible-wrapper button,
.seconday-button.ant-tooltip-disabled-compatible-wrapper button {
  color: white !important;
  opacity: 0.7 !important;
}
.seconday-button.ant-tooltip-disabled-compatible-wrapper button {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.onboard-antd.ant-tour .ant-tour-inner .ant-tour-footer {
  display: none !important;
}
.onboard-antd.ant-tour .ant-tour-inner {
  padding: 0 8px !important;
}
.onboard-antd.ant-tour .ant-tour-inner .ant-tour-close {
  margin-top: 8px;
}

.onboard-antd.ant-tour .antd-navigate-footer {
  display: flex;
  gap: 8;
  padding: 24px 0 12px;
  justify-content: space-between;
  align-items: center;
}

.ant-tour .ant-tour-buttons {
  display: flex;
  align-items: center;
  gap: 3px;
}
.ant-tour .ant-tour-buttons .ant-tour-prev-btn,
.ant-tour .ant-tour-buttons .ant-tour-next-btn {
  display: flex;
  align-items: center;
}

.ant-btn-default.ant-btn-dangerous.stripe-onboard {
  color: red !important;
  border-color: red !important;
}

.welcome-screen-img {
  border: 1px solid white;
  border-radius: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.1);
}

.button-secondary-color-important {
  background-color: #0588ca !important;
  color: white !important;
}

.button-secondary-color-important:disabled {
  opacity: 0.7;
}

.support-button.ant-btn.ant-btn-default:focus .ant-wave,
.support-button.ant-btn.ant-btn-default:active .ant-wave {
  display: none !important;
}

.welcome-screen {
  margin: 60px 40px;
  display: flex;
  overflow: auto;
  justify-items: center;
  flex-direction: column;
}
.welcome-screen-container,
.admin-invite-screen {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 60px;
}

.admin-invite-screen {
  box-sizing: border-box;
  padding: 60px 40px;
  align-items: center;
  width: 100% !important;
}

.card-wrapper {
  padding: 12px 12px;
  background-color: white;
  border-radius: 12px;
  margin: 0;
}

.bulk-address-notification .ant-notification-notice-description {
  max-height: 400px;
  overflow-y: scroll;
}

.bulk-address-notification
  span.anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-error {
  font-size: 24px !important;
}

.account-balance.ant-card {
  max-width: fit-content;
}

@media screen and (max-width: 1024px) {
  .welcome-screen-container {
    flex-direction: column;
  }
}

.captcha-visible {
  visibility: visible;
}

/* agency all licenses */
.agency-all-licenses .ant-collapse-header,
.agency-all-licenses .ant-collapse {
  background-color: #ffffff !important;
}

.agency-all-licenses
  .ant-collapse
  .ant-collapse-item:nth-child(1)
  .ant-collapse-header {
  margin-top: 4px;
}

.agency-all-licenses .ant-collapse .ant-collapse-content {
  border-top: none !important;
}

.agency-licenses-list-left-pane.agency-list-border {
  width: 200px !important;
}

.agency-list-border {
  border-right: 1px solid #dfedf5;
  padding: 16px 32px 16px !important;
  margin: -16px 0;
  width: 226px !important;
}

.agency-licenses-list-left-pane {
  display: flex;
  flex-direction: column;
  gap: 12;
  font-weight: 500;
}

.agency-licenses-list-left-pane div:last-child {
  padding: 0 0 12px;
}

.agency-licenses-list-right-pane {
  flex: 1;
}

.agency-all-licenses
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header,
.agency-all-licenses .ant-collapse-content.ant-collapse-content-active,
.agency-all-licenses .ant-collapse.collapse-first-child-open {
  background-color: #edf9ff !important;
}
.agency-all-licenses
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 16px 16px;
}
.all-licenses-agency-map {
  display: flex;
  gap: 24px;
}
.all-licenses-agency-map .map-container {
  flex-basis: 60%;
  border: 1px solid #dfedf5;
}
.all-licenses-agency-map .map-container.w-full {
  flex-basis: 100%;
}
.all-licenses-agency-map .map-container,
.all-licenses-agency-map .map-details-container {
  padding: 16px;
  border-radius: 8px;
  max-height: 600px;
  overflow-y: auto;
}

.all-licenses-agency-map .map-details-container {
  flex-basis: 40%;
  border: 1px solid #dfedf5;
  background-color: #edf9ff;
}

#search-input-agency-all-licenses-tab,
.agency-all-licenses .ant-input-search .ant-input-search-button {
  height: 36px !important;
}

.individual-onboard-assignment-card .ant-card-body {
  height: 100% !important;
  padding: 16px !important;
}
.individual-onboard-v2-options .ant-card-body {
  padding: 22px 16px !important;
}

.individual-onboard-v2-options .ant-form-item .ant-form-item-label > label,
.individual-onboard-v2-details .ant-form-item .ant-form-item-label > label {
  font-size: 14px !important;
  color: #222222 !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  font-family: 'Poppins';
}

.header-title {
  font-size: 20px !important;
  color: #001f45 !important;
  line-height: 28px !important;
  font-weight: 600 !important;
  font-family: 'Poppins';
}

.individual-onboarding-welcome-card .ant-card-body {
  padding: 0 !important;
}
/* .individual-onboard-assignment-card .ant-card-body .ant-card {
  height: 100% !important;
} */

.individual-onboarding-v2-layout
  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button'] {
  cursor: default I !important;
}

.hide-bottom-tab-border .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.hide-bottom-tab-border .ant-tabs-nav-list::after {
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  border-bottom: 1px solid #e5e7eb !important;
  content: '';
}
.hide-bottom-tab-border .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 1px !important;
}

.sub-heading {
  color: #001f45 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 28px !important;
}

.sub-heading-small {
  color: #001f45 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
}

.biographic-info .ant-select-single.ant-select-open .ant-select-selection-item {
  color: unset !important;
}
.bottom-remove-padding .ant-card-body {
  padding: 24px 24px 0 !important;
}

/* custom - collapse */
.custom-collapse .ant-collapse-header {
  background: #ffffff;
  border-radius: 8px;
}
.custom-collapse .hide-header .ant-collapse-header {
  display: none;
}
.custom-collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: unset;
}

.no-wrap {
  text-wrap: nowrap !important;
}

.custom-collapse .ant-collapse-header[aria-expanded='false'] {
  margin-bottom: 12px !important;
  transition:
    margin-bottom 0.3s ease,
    background-color 0.3s ease;
}
.save-list-button .ant-btn .ant-btn-icon .anticon svg {
  width: 12px !important;
  height: 12px !important;
}

.producer-list-hover:hover {
  color: #00a6fb !important;
}

/*  */

@media screen and (max-width: 1024px) {
  .all-licenses-agency-map {
    flex-direction: column;
  }
}

@media screen and (min-height: 900px) {
  .all-licenses-agency-map .map-container,
  .all-licenses-agency-map .map-details-container {
    max-height: 800px;
  }
}

@keyframes slideUpIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUpOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.transition-slide-in {
  animation: slideUpIn 1s forwards;
}

.transition-slide-out {
  animation: slideUpOut 1s forwards;
}
