.heading {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--secondary-color);
}

.content,
.content-two,
.content-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}
