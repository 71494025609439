/* ant typography margin bottom unser */
.ant-typography.mb-0 p,
.ant-typography.m-0 p,
.ant-typography.mb-0,
.ant-typography.m-0 {
  margin-bottom: 0 !important;
}

.ant-typography.mb-6px p,
.ant-typography.mb-6px {
  margin-bottom: 6px !important;
}
/*  */

.table-layout-v2 .ant-table-wrapper .ant-table-thead > tr > th,
.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography {
  font-family: var(--font-family-figtree) !important;
  font-size: var(--font-size-small) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-small) !important;
  letter-spacing: 0.005em !important;
  text-align: left !important;
  color: var(--color-primary) !important;
}
.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.text-secondary {
  color: #0074b0 !important;
}
.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.text-cement {
  color: rgb(132, 132, 132) !important;
}
.table-layout-v2 .ant-table-wrapper .ant-table-thead > tr > th {
  margin-left: 12px !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.font-14px {
  font-size: 14px !important;
}

.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected.ant-menu-item-active::after {
  border-bottom-width: 2px !important;
  border-bottom-color: #00a6fb !important;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-active {
  color: #00a6fb !important;
}

.hide-asterisk.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: none;
}

.label-align.ant-form-item .ant-form-item-label > label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.custom-footer-modal.ant-modal .ant-modal-footer {
  display: flex;
  justify-self: flex-end;
}

.ul-override-style ul {
  list-style: disc;
  padding-left: 1.25rem;
}

/* auto renew switch css override */
.auto-renew-switch.ant-switch.ant-switch-checked:not(.ant-switch-loading)
  .ant-switch-handle::before,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper
  .ant-switch.ant-switch-checked:not(.ant-switch-loading)
  .ant-switch-handle::before {
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  background-image: url('../assets/icons/success.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9px;
  transition: all 0.2s ease-in-out;
  content: '';
  z-index: 100;
}

.auto-renew-switch.ant-switch:not(.ant-switch-loading)
  .ant-switch-handle::before,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper
  .ant-switch:not(.ant-switch-loading)
  .ant-switch-handle::before {
  background-image: url('../assets/icons/error.svg');
  background-size: cover;
  background-color: #facbcb;
  z-index: 100;
  border: 0.68px solid #f59898;
}

.auto-renew-switch.ant-switch.ant-switch-checked,
.auto-renew-switch.ant-switch.ant-switch-checked:hover,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper
  .ant-switch.ant-switch-checked,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper
  .ant-switch.ant-switch-checked:hover {
  background-color: #cbfae3;
  border: 0.68px solid #98f5c8;
}

.auto-renew-switch.ant-switch,
.auto-renew-switch.ant-switch:hover,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper .ant-switch,
.auto-renew-switch.ant-tooltip-disabled-compatible-wrapper .ant-switch:hover {
  background-color: #facbcb;
  border: 0.68px solid #f59898;
}
/*  */

.hide-arrow .ant-popover-arrow {
  display: none;
}
