.siderMenu .ant-menu-item:hover {
  background-color: #edf9ff !important;
  color: #00a6fb !important;
}

.siderMenu .ant-menu-item {
  height: 32px;
}

.siderMenu .ant-menu-item-only-child {
  height: 32px;
}

.siderMenu .ant-menu-item-selected {
  background-color: #001836 !important;
  color: #fafafa !important;
  border-radius: 8px;
}

.ant-menu-submenu-selected .ant-menu-item-selected,
.siderMenu .ant-menu-submenu .ant-menu-item.ant-menu-item-selected {
  color: #001836 !important;
  background-color: #fafafa !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #001f45 !important;
  color: #fafafa !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title .anticon {
  filter: brightness(0) invert(1);
}

.siderMenu .ant-menu-sub {
  margin-left: 42px;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-sub
  .ant-menu-item-selected
  > .ant-menu-title-content {
  border-radius: 14px !important;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-sub
  .ant-menu-item
  > .ant-menu-title-content:hover {
  border-radius: 14px !important;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: white;
}

.ant-menu-light.ant-menu-inline .ant-menu-item {
  padding-left: 15px !important;
  border-radius: 0px;
  margin: 0 4px;
  gap: 2px;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-sub
  .ant-menu-item-selected
  > .ant-menu-title-content {
  background-color: #daf2ff !important;
  border: 1px solid #daf2ff !important;
  border-radius: 8px;
  padding-left: 10px;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-sub
  .ant-menu-item
  > .ant-menu-title-content {
  padding-left: 10px;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-item:hover:not(#sider-menu-home)
  > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-item:hover:not(#sider-menu-billing)
  > .ant-menu-title-content {
  background-color: #edf9ff !important;
  color: #00a6fb !important;
  border: 0 solid #edf9ff !important;
  border-radius: 8px;
}

.siderMenu .ant-menu-sub .ant-menu-item:hover {
  background-color: #fafafa !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.siderMenu {
  background-color: #fafafa !important;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #fafafa !important;
}

#sider-menu-home,
#sider-menu-billing {
  border-radius: 8px !important;
  margin-left: 16px;
  margin-right: 16px;
  width: unset;
  height: 32px;
  margin-bottom: 4px;
}

#sider-menu-home:hover,
#sider-menu-billing:hover {
  background-color: #edf9ff !important;
}
.siderMenu .ant-menu-item-selected .anticon {
  filter: brightness(0) invert(1);
}

#sider-menu-home:hover .anticon,
#sider-menu-billing:hover .anticon {
  filter: none;
  background-color: #edf9ff !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  width: unset;
  margin-inline: 16px;
}

.ant-menu-item.ant-menu-item-only-child {
  margin-bottom: 8px !important;
}

.remove-margin .ant-menu-item.ant-menu-item-only-child {
  margin-bottom: 0 !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  position: relative;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.ant-menu.ant-menu-sub.ant-menu-inline::before {
  content: '';
  position: absolute;
  top: 0px;
  left: -3px;
  bottom: 20px;
  height: calc(100% - 20px);
  width: 2px;
  background-color: #e6e6e6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-menu-submenu-title {
  padding-left: 16px !important;
}

.ant-menu-submenu-title .ant-menu-title-content {
  margin-left: 8px;
}
.ant-card-body {
  padding: 16px !important;
}

.bulk-onboarding-layout .ant-card-body {
  padding: 0px !important;
}
