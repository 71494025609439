@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #00a6fb;
  --primary-color-light: #d0e9f5;
  --secondary-color: #07212d;
  --hint-secondary-color: #0b374d;
  --secondary-light-color: #f7fcfc;
  --hint-primary-color: #0588ca;
  --sub-heading-color: #525252;
  --border-color: #dff4f5;
  --light-grey1: #acb9bf;
  --light-grey2: #99acb5;
  --light-grey3: #f0f0f0;
  --grey-text: #858585;
  --grey-heading: #4d6f80;
  --hover-color: #f2fbff;
  --selection-color: #d0e9f5;
  --important-color: #e91e1e;
  --important-highlight-color: rgb(255, 222, 222);
  --success-color: #00aa11;
  --orange-color: #9f7200;
  --tag-success-color: #c4edeb;
  --tag-failure-color: #ff7875;
  --black-color: #000000;
  --red-color: #f00f00;

  --font-family-figtree: 'Figtree', sans-serif;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-weight-regular: 400;
  --line-height-small: 16px;
  --line-height-medium: 20px;
  --color-primary: #001f45;
  --color-secondary: #437ee1;
  --color-background-light: #fafafa;
  --color-background-highlight: #d3e7ff;
  --color-background-territory: #fcefcf;
  --color-border-territory: #f8d79a;
  --color-text-light: #ffffff;
}

body {
  margin: 0;
  font-family: 'Figtree';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  min-height: 70px;
}

.header > .ant-card-body,
.width-100 {
  width: 100%;
}

code {
  font-family: 'Poppins' !important;
}

.ant-menu-title-content,
.label.ant-radio-button-wrapper,
.ant-table-content,
.ant-tag,
.ant-avatar,
.ant-btn,
.ant-input,
.ant-select,
.ant-select-selection-item,
.ant-steps,
.ant-badge,
.ant-collapse,
.ant-descriptions {
  font-family: 'Figtree' !important;
}

.figtree.ant-btn {
  font-family: 'Figtree' !important;
  display: inline-flex !important;
  align-items: center !important;
}

.ant-typography {
  font-family: 'Figtree' !important;
  font-weight: 400;
}

.ant-table-thead > tr > th {
  color: #787878 !important;
}

.primary-line {
  border: 0.1px dashed var(--border-color) !important;
  background-color: var(--border-color);
}
.secondary-line {
  border: 0.1px dashed #e6e6e6 !important;
  background-color: #e6e6e6;
}

.button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.button:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.button:focus {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

#offboard-agents-individual-offboarding-button,
#remove-territories {
  display: flex;
  align-items: center;
}

.ant-btn-default.text-white:not(:disabled):hover {
  color: #ffffff !important;
}

.disabled-action-button,
.disabled-action-button:hover,
.disabled-action-button:focus {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  opacity: 1 !important;
  border-radius: 8px !important;
}
.disabled-action-button .ant-btn-default:disabled {
  color: rgba(0, 0, 0);
}

.disabled-action-button button.ant-btn-default:disabled {
  display: flex;
  align-items: center;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  display: flex;
  justify-content: center;
}

.ant-modal-footer-flex-justify-end .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal.zero-padding .ant-modal-content,
.ant-modal.zero-padding .ant-modal-body {
  padding: 0;
}

.important-action-button,
.important-action-button:hover,
.important-action-button:focus,
.important-action-button.ant-btn-primary,
.important-action-button.ant-btn-primary:hover,
.important-action-button.ant-btn-primary:focus {
  background-color: var(--important-color) !important;
  border-color: var(--important-color) !important;
  color: white !important;
}

.secondary-button,
.secondary-button:disabled,
.secondary-button.ant-btn-primary:disabled {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.secondary-button:hover,
.secondary-button.ant-btn-primary:hover,
.secondary-button:focus,
.secondary-button.ant-btn-primary:focus {
  background-color: var(--hint-secondary-color) !important;
  border-color: var(--hint-secondary-color) !important;
}

.plain-button:hover {
  border-color: var(--hint-primary-color) !important;
  background-color: #ffffff !important;
}

.ternary-button {
  background-color: #dff4f5 !important;
  border-color: #dff4f5 !important;
  color: #2a6265 !important;
}

.ternary-button:hover {
  background-color: #c7e4e6 !important;
  border-color: #c7e4e6 !important;
  color: #2a6265 !important;
}

.ternary-button:focus {
  background-color: #c7e4e6 !important;
  border-color: #c7e4e6 !important;
  color: #2a6265 !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  color: var(--important-color);
  content: '*';
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.display-input {
  background-color: var(--secondary-light-color) !important ;
  border-color: #f0feff !important;
  color: #222222 !important;
}

.ant-form-item .ant-form-item-label {
  font-weight: 500;
}

.onboarding-screen .ant-input,
.onboarding-screen .ant-select {
  /* border-color: #dff4f5; */
  border: 0px solid #a0a0a0;
  border-radius: 6px;

  /* Added by RB - New onboarding flow */
  height: 36px;
  min-width: 200px;
  background: #f5f6f7;
}

.onboarding-screen .ant-form-item-control-input-content > input {
  height: 36px;
}

.onboarding-screen .ant-input-affix-wrapper {
  border: 0px;
  background: #f5f6f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

.onboarding-screen .ant-form-input,
.onboarding-screen .ant-form-item {
  margin-bottom: 0px;
}

.onboarding-screen .ant-input:hover,
.onboarding-screen .ant-input:focus,
.onboarding-screen .ant-input-focused,
.onboarding-screen .ant-input-affix-wrapper:hover,
.onboarding-screen .ant-input-affix-wrapper:focus,
.onboarding-screen .ant-input-affix-wrapper-focused {
  border: 0.1px solid #29bfff;
  box-shadow: 0 0 0 2px rgba(5, 215, 255, 0.1);
  border-inline-end-width: 1px;
  outline: 0;
}

.onboarding-screen
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.onboarding-screen
  .ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border: 0.1px solid #ff4d4f;
}

.ant-steps .ant-steps-item-title {
  font-size: 14px;
}

.onboarding-radio-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.onboarding-radio-button
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after {
  background-color: white !important;
}

#background-question
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after {
  background-color: white !important;
}

.onboarding-radio-button > label {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #f5f6f7;
  padding: 14px 20px;
  background: #f5f6f7;
  border-radius: 6px;
  width: 100%;
  justify-content: space-between;
}

.onboarding-radio-button .ant-radio-wrapper.ant-radio-wrapper-checked {
  border: 1px solid #00a6fb;
}

.onboarding-radio-button
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner {
  border-color: none !important;
}

#background-question
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner {
  border-color: none !important;
}

.onboarding-radio-button > label::after {
  display: none;
}

.onboarding-radio-button-note {
  background: #f4fbfe;
  padding: 10px;
  border-radius: 6px;
  max-width: 616px;
}
.onboarding-radio-button-note > .ant-typography {
  font-size: 12px;
  margin-bottom: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.ant-notification-notice-message {
  font-weight: 500;
}
.anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-error {
  margin-top: 5px;
}
/* END Added by RB - New onboarding flow */

.custom-icon {
  color: #00ff00;
}

.ant-picker {
  width: 100%;
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #acb9bf;
  border-radius: 6px;
}

.custom-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  background-color: var(--grey-text);
  border-radius: 50%;
}

.custom-carousel .slick-dots li.slick-active button {
  background-color: var(--primary-color);
}

/* AgentRtsByStates */
.agency-rts-controls,
.flex-center,
.flex-col-center,
.text-center.ant-card,
.text-center.ant-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col-center,
.text-center.ant-card,
.text-center.ant-card .ant-card-body {
  flex-direction: column;
}

.agency-rts-controls {
  margin-bottom: 24px;
  gap: 24px;
}

.agents-rts-carriers .ant-input-affix-wrapper {
  height: 32px;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.pl-24 {
  padding: 0 24px 0;
}

.font-12,
.custom-placeholder::placeholder,
.custom-placeholder .ant-input::placeholder {
  font-size: 12px;
}

.weight-400 {
  font-weight: 400;
}
.line-height-16 {
  line-height: 16px;
}
.line-height-20 {
  line-height: 20px;
}
.cursor-pointer {
  cursor: pointer;
}

#filters-button:disabled,
#agents-button:disabled,
.button:disabled {
  opacity: 0.7 !important;
}

#filters-button .ant-btn-loading-icon {
  color: #ffffff;
}

.filter-checkbox .ant-checkbox {
  align-self: flex-start;
  margin-top: 2px;
}

/*  */

.square {
  height: 20px;
  width: 20px;
}

.invite-admin-form .ant-form-item {
  margin-bottom: 6px;
}

.onboard-active
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-active::after {
  border-bottom-color: transparent;
}

.agency-chart .ant-card-body > .ant-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.stats-card .ant-card-body,
.stats-card .ant-statistic {
  height: 100%;
}
.stats-card .ant-statistic {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.custom-date-button:hover {
  color: unset !important;
}

.offboard-agents .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.ant-custom-picker-popover.ant-popover-disabled-compatible-wrapper {
  min-width: 100%;
}

.ant-custom-picker-popover.ant-popover-disabled-compatible-wrapper > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorModal .ant-modal-body {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.anticon-info-circle {
  color: var(--primary-color);
}

.reset-color .anticon-info-circle {
  color: inherit !important;
}

.anticon-info-circle.text-E2A200 {
  color: #e2a200 !important;
}

.disabled-row {
  background-color: #f5f5f5;
}

.custom-drlp-dropdown .ant-empty {
  display: none !important;
}

.override-checkbox-bg
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #001f45 !important;
  border-color: #001f45 !important;
}
.override-checkbox-bg
  .ant-checkbox-checked.ant-checkbox-disabled
  .ant-checkbox-inner {
  /* background-color: #00a6fb !important; */
  border-color: #001f45 !important;
}
.override-checkbox-bg .ant-checkbox-checked:after {
  border: none !important;
}

.override-checkbox-bg .ant-checkbox {
  border-radius: 0 !important; /* Remove any border radius */
}

.override-checkbox-bg .ant-checkbox-inner {
  border-radius: 0 !important; /* Ensure the inner box is square */
}

.override-checkbox-bg .ant-checkbox-input {
  border-radius: 0 !important; /* Ensure the input area is square */
}

.custom-drlp-dropdown.ant-select-dropdown {
  padding: 0px !important;
}

.dropdown-background .ant-select-selector {
  background-color: #f5f6f7 !important;
}

.icon-width-14 span.anticon {
  font-size: 14px !important;
}

.override-icon-size span.anticon.outlined {
  font-size: 24px !important;
}
.override-icon-size span.anticon-close-circle {
  font-size: 20px !important;
}

.overide-icon-size span.anticon-phone,
.overide-icon-size span.anticon-mail,
.overide-icon-size.font-12 span.anticon {
  font-size: 12px !important;
}
.reset-icon-size .override-icon-size.font-10 span.anticon,
.override-icon-size.font-10 span.anticon {
  font-size: 10px !important;
}

.producer-onboarding-cards .ant-card-body,
.signup-card .ant-card-body {
  padding: 0 !important;
}
.complete-producer-profile .ant-card-body {
  padding: 22px !important;
}

.bg-collapsible-container .ant-collapse-header {
  background-color: #daf2ff !important;
}
.bg-collapsible-container .ant-collapse-content,
.bg-collapsible-container .ant-spin-container div {
  background-color: #edf9ff !important;
}

.ownership-details .ant-radio-checked .ant-radio-inner,
.individual-onboard-v2-options .ant-radio-checked .ant-radio-inner,
.individual-onboard-v2-details .ant-radio-checked .ant-radio-inner {
  border-color: #ffffff !important;
}

.individual-onboard-v2-details .ant-switch.ant-switch-checked {
  background-color: #032b3f !important;
}

.individual-onboard-v2-options .ant-select-selector,
.individual-onboard-v2-options .ant-form-item .ant-form-item-control-input,
.individual-onboard-v2-options
  .ant-form-item
  .ant-form-item-control-input
  input,
.individual-onboard-v2-details .ant-select-selector,
.individual-onboard-v2-details .ant-form-item .ant-form-item-control-input,
.individual-onboard-v2-details
  .ant-form-item
  .ant-form-item-control-input
  .ant-picker,
.individual-onboard-v2-details
  .ant-form-item
  .ant-form-item-control-input
  input {
  height: 36px !important;
}
.individual-onboard-v2-options .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  padding-top: 3px !important;
}

.ownership-details
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after,
.individual-onboard-v2-options
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after,
.individual-onboard-v2-details
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after {
  background-color: white !important;
}

.ownership-details-popover.ant-popover .ant-popover-inner {
  padding: 16px 0 !important;
  border: 1px solid #a7ceff !important;
  color: #fafdff !important;
}

.individual-onboarding-v2-layout
  .ant-checkbox-checked:hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.individual-onboarding-v2-layout
  .ant-checkbox-wrapper:hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.individual-onboarding-v2-layout
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: rgba(0, 31, 69, 1) !important;
  border-color: rgba(0, 31, 69, 1) !important;
}
.individual-onboarding-v2-layout .ant-checkbox-checked:after,
.individual-onboarding-v2-layout .ant-checkbox:hover::after,
.individual-onboarding-v2-layout .ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgba(0, 31, 69, 1) !important;
}

.individual-onboarding-addresses {
  background-image: url('../assets/images/addressBg.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
  border-radius: 8px;
  overflow: hidden;
}

.individual-onboarding-agent-onboarded {
  background-image: url('../assets/images/newAgentOnboardWelcomeBg.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
  height: 100vh;
}

.font-poppins {
  font-family: 'Poppins' !important;
}

.signup-address {
  background-image: url('../assets/images/addressBg.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
  overflow: hidden;
}

/* manage assignments    */

.figtree {
  font-family: var(--font-family-figtree) !important;
  font-optical-sizing: auto;
  font-style: normal;
}

@media (min-width: 1480px) {
  .full-width-on-large {
    width: 100%;
  }
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th.ant-table-cell.align-center {
  text-align: center !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.text-bold {
  font-size: var(--font-size-medium) !important;
  font-weight: 600 !important;
}

.table-layout-v2
  .ant-table-wrapper
  th.ant-table-cell
  .ant-table-column-sorters {
  justify-content: unset !important;
  align-items: center !important;
}

.table-sort-center.ant-table-wrapper .ant-table-column-sorters {
  justify-content: unset !important;
}

.ant-table-wrapper th.ant-table-cell.center-column .ant-table-column-sorters {
  justify-content: center !important;
}

.table-sort-center.ant-table-wrapper
  th.ant-table-cell
  .ant-table-column-sorters
  .ant-table-column-title,
.table-layout-v2
  .ant-table-wrapper
  th.ant-table-cell
  .ant-table-column-sorters
  .ant-table-column-title {
  flex: unset !important;
}

.table-layout-v2
  .ant-table-wrapper
  th.ant-table-cell.align-center
  .ant-table-column-sorters {
  justify-content: center !important;
}

.table-sort-center.ant-table-wrapper
  th.ant-table-cell
  .ant-table-column-sorters
  .ant-table-column-title,
.table-layout-v2
  .ant-table-wrapper
  th.ant-table-cell
  .ant-table-column-sorters
  .ant-table-column-title {
  flex: unset !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.margin-unset {
  margin-left: unset !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th.ant-table-cell.ant-table-selection-column {
  text-align: center !important;
  padding-inline-end: 8px !important;
  padding-inline-start: 8px !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell
  .ant-typography.font-14 {
  font-size: var(--font-size-medium) !important;
  font-weight: 600 !important;
}

.table-layout-v2 .ant-table-wrapper .ant-table-thead > tr > th {
  padding: 5px 5px 5px 8px !important;
  height: 36px !important;
  background-color: var(--color-background-light) !important;
}

.agency-license-table .ant-table-wrapper .ant-table-thead > tr > th {
  height: 36px !important;
}

.table-layout-v2 .ant-table-column-sorter-inner .anticon-caret-up,
.table-layout-v2 .ant-table-column-sorter-inner .anticon-caret-down {
  font-size: 9px !important;
  margin-left: 12px !important;
}

.table-layout-v2
  .ant-table-wrapper
  .ant-table-thead
  .ant-table-column-sorter-inner {
  color: #000000 !important;
}

.table-layout-v2 .ant-table-wrapper .ant-table-tbody td.ant-table-cell {
  padding: 5px 5px 5px 12px !important;
}
.table-layout-v2
  .ant-table-wrapper
  .ant-table-tbody
  td.ant-table-cell.ant-table-selection-column {
  padding-left: 0 !important;
}

.ant-custom-avatar.ant-avatar-sm,
.custom-avatar-group .ant-custom-avatar.ant-avatar-sm .ant-avatar-string {
  font-family: var(--font-family-figtree) !important;
  font-size: 10px !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-small) !important;
  border-radius: 26px !important;
}

.ant-custom-avatar.ant-avatar-sm {
  border: 1px solid var(--color-secondary);
  color: #1d2c50;
  background: #ffffff;
  padding: 3px 6px 2px !important;
}

.custom-avatar-group .ant-avatar-sm:not(.ant-custom-avatar) {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-text-light) !important;
}

.custom-territory-name,
.custom-badge {
  font-family: var(--font-family-figtree) !important;
  font-weight: var(--font-weight-regular);
  border-radius: 26px;
  border: 1px;
  text-align: center;
}

.custom-territory-name {
  font-size: var(--font-size-small);
  line-height: var(--line-height-medium);
  padding: 1px 6px;
  gap: 4px;
  color: var(--color-primary);
  border: 1px solid var(--color-border-territory);
  background-color: var(--color-background-territory);
}

.custom-badge {
  width: 24px;
  height: 24px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-badge-text {
  font-family: var(--font-family-figtree) !important;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  background: var(--color-primary);
  text-wrap: nowrap;
}

.table-layout-v2 .ant-pagination-total-text {
  position: absolute !important;
  left: 0 !important;
}

.table-layout-v2 .ant-pagination {
  font-family: var(--font-family-figtree) !important;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: 24px;
  text-align: center;
}

.table-layout-v2 .ant-table-row.selected-producer-row,
.table-layout-v2 .ant-table-row.selected-producer-row .ant-table-cell-row-hover,
.table-layout-v2 .ant-table-row.selected-producer-row td.ant-table-column-sort {
  background: var(--color-background-highlight) !important;
}

.remove-padding.ant-card .ant-card-body {
  padding: 0 !important;
}

.relative {
  position: relative !important;
}

.sub-title-custom {
  font-family: var(--font-family-figtree) !important;
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  line-height: 12px;
  text-align: center;
}

.sub-title-placeholder {
  font-family: var(--font-family-figtree) !important;
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  line-height: 20px;
}

.medium-title-custom {
  font-family: var(--font-family-figtree) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.producer-assignment-card .ant-card-body {
  padding: 8px 20px 8px 22px;
}

.text-center {
  text-align: center !important;
}

.territory-wrapper .ant-tooltip-inner {
  background-color: #ffffff !important;
}

.territory-wrapper.ant-tooltip .ant-tooltip-arrow:before {
  background: #fbf5f5 !important;
}

.override-lottie-180 svg {
  width: 180px !important;
  height: 180px !important;
}
.ant-modal-close-selected-producer span:hover {
  background: rgba(200, 199, 199, 0.45);
  cursor: pointer;
}

/*  */
.custom-autocomplete .ant-input[role='combobox'] {
  padding-left: 10px !important;
}

.cell-with-lines {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 8px;
}

.custom-header-cell {
  color: #001f45 !important; /* Change to your preferred color */
}

.cell-with-lines {
  padding: 0 16px;
}

.right-aligned-modal {
  position: fixed;
  right: 0;
  top: 70px;
  bottom: 0;
  margin: 0;
  width: 400px;
}

.right-aligned-modal .ant-modal-content {
  height: -webkit-fill-available;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border: 0;
  border-radius: 0;
}

.agency-license-buttons {
  font-weight: 500;
  background-color: #f5ffff;
  border-color: #00a6fb;
  padding: 0px 12px;
  height: 24px;
  font-size: 12px;
  font-family: Figtree !important;
  line-height: 22px;
}

.custom-search ::placeholder {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.custom-search {
  width: 100%;
  min-width: 311px;
  height: 28;
  border: 1px solid #dfedf5;
  opacity: 1;
}

.custom-table-header .ant-table-thead > tr > th {
  display: flex;
  height: 36px;
  padding: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  flex-direction: row;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 8px 20px;
  font-family: Figtree;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #001f45 !important;
  background-color: #fafafa !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td.agency-license-state-row {
  padding: 5px 20px !important;
  font-size: 12px;
}

.agency-license-table.ant-table-wrapper .ant-table-thead > tr > th:last-child,
.agency-license-table.ant-table-wrapper .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.agency-license-table.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important; /* Ensure no extra border on the last row */
}
.agency-license-table.ant-table-wrapper {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.agency-license-table.ant-table-wrapper table .ant-typography {
  font-family: 'Figtree' !important;
  font-size: 12px;
}

.agency-license-table .ant-table-column-sorter-up,
.agency-license-table .ant-table-column-sorter-down {
  color: black;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.ant-drawer-right > .ant-drawer-content-wrapper:not(.multiple-onboard-preview) {
  box-shadow: none;
  position: absolute;
  top: 0;
}

.top-0-drawer.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
}

.multiple-onboard-preview > .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 32px 42px;
}

/* v2-filter-button */
.v2-filter-button #filters-button.ant-btn {
  height: 28px !important;
  line-height: 22px !important;
  background: #e8ebee !important;
  padding: 2px 16px !important;
}

.v2-filter-button #filters-button.ant-btn .anticon.anticon-loading {
  color: #001f45 !important;
}

/* v2-search */
.v2-search .ant-input-wrapper .ant-input,
.v2-search .ant-input-wrapper .ant-input-group-addon button {
  height: 28px !important;
}

.text-base-style,
.v2-search .ant-input-wrapper .ant-input,
.v2-search .ant-input-wrapper .ant-input-group-addon button,
.v2-filter-button #filters-button.ant-btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: 'Figtree' !important;
  color: #001f45 !important;
}

.individual-apply-button .ant-tooltip-disabled-compatible-wrapper button {
  display: grid;
  place-content: center;
}

.ant-tooltip-disabled-compatible-wrapper button {
  align-items: center;
  display: flex;
}

.ant-message
  .ant-message-notice.clone-error-message
  .ant-message-error
  .anticon.anticon-close-circle {
  color: #ff4d4f;
}
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  min-width: 314px;
  padding: 12px 8px 8px 8px;
}
.ant-drawer .ant-drawer-body {
  overflow: auto !important;
}

.header .ant-card-body {
  padding: 0 20px !important;
}

.sider-menu-img-card.ant-card {
  background: url('/src/assets/images/Sidebar.png');
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 1200px !important ; /* max-height */
  align-items: flex-end;
  margin: 0 !important;
}

.menu-item-agency:hover {
  background-color: #f7f7f7 !important;
}

.menu-item-agency-selected {
  background-color: #eeeeee !important;
}

.menu-item-agency-selected:hover {
  background-color: #eeeeee !important;
}

.need-help-sidebar .ant-popover-inner {
  padding: 16px !important;
  border: 1px solid #a7ceff;
}

.need-help-sidebar.ant-popover .ant-popover-arrow:before {
  background-color: #d3e7ff !important;
  border: 1px solid #d3e7ff !important;
}

.bulk-onboarding-layout {
  padding: 0 !important;
}

.continue-onboarding-modal .ant-modal-content {
  padding: 0 !important;
  width: 464px !important;
}

.ant-card.ant-card-bordered.sider-menu-img-card > .ant-card-body {
  width: 100%;
}

.ant-typography {
  font-family: var(--font-family-figtree) !important;
}

.captcha-hidden {
  visibility: hidden !important;
}
.bulk-onboarding-layout .ant-layout-content > .ant-card .ant-card-bordered {
  border: none;
}

.error-row {
  background-color: #facbcb;
}

.disable-row {
  cursor: not-allowed;
}

.enable-row {
  cursor: pointer;
}

.ant-card.bulk-manage-producers {
  height: 109px !important;
  padding: 16px 20px 16px 22px;
}

.bulk-manage-producers > .ant-card-body {
  padding: 0 !important;
}

/* hide toggle icon in dropdown for individual producer */
.ant-dropdown-trigger.hide-toggle > img {
  display: none;
}

/* disable primary button  */
.ant-btn-primary:disabled {
  color: #fff !important;
  background-color: #00a6fb !important;
  box-shadow: 0 2px 0 rgba(5, 215, 255, 0.1) !important;
}
.ant-btn-primary:hover {
  background-color: #008ac6 !important;
  color: #fff !important;
}

#agency-all-licenses-apply-button.ant-btn-primary:disabled {
  background-color: rgb(0, 24, 54) !important;
  opacity: 0.6 !important;
}
/**/

.MuiCircularProgress-root.MuiCircularProgress-determinate.MuiCircularProgress-colorPrimary.MuiCircularProgress-variantSoft.MuiCircularProgress-sizeLg {
  font-size: 12px;
}

/* hover reset color */
.hover-reset.ant-btn-default:not(:disabled):active,
.hover-reset.ant-btn-default:not(:disabled):hover {
  color: inherit !important;
}
.ant-btn-default:not(:disabled):active .button-icon,
.ant-btn-default:not(:disabled):hover .button-icon {
  fill: var(--primary-color);
}

.responsive-container {
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: auto;
  max-height: calc(100vh - 128px);
}

.onboarding-screen {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.form-header {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .responsive-container {
    padding: 32px 24px;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    row-gap: 22px;
    top: 128px; /* header image + logout button */
    position: fixed;
    left: 0;
    right: 0;

    bottom: 0;
    overflow-y: auto;
    max-height: calc(100vh - 128px);
  }

  .onboarding-steps {
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
  }

  .onboarding-steps .ant-steps-item {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .onboarding-steps.ant-steps .ant-steps-item:last-child {
    flex: 1;
  }

  .onboarding-steps .ant-steps-item-title::after {
    content: none;
  }

  .ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    height: 0;
    width: 0;
  }

  .form-header h2 {
    margin-bottom: 2.78px;
    font-size: 18px;
    font-weight: 600;
  }
  .form-header .ant-typography-secondary {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: #97aabf;
  }

  .onboarding-screen {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-row {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 16px;
  }

  .form-header {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .onboarding-steps .ant-steps-item-title {
    width: 80px;
    text-align: center;
  }

  .onboarding-steps .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-otp .input-otp__field {
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    max-width: 32px;
    text-align: center;
    width: 100%;
    padding: 0;
  }
}

@media (max-height: 768px) {
  .signup-logo {
    height: 42.8px;
    width: 164px;
  }
}

@media (min-height: 768px) {
  .signup-logo {
    height: 52.33px;
    width: 200.5px;
  }
}

.card-details-modal .ant-modal-content {
  padding: 0 !important;
}

.ant-notification-notice-content > .anticon,
.anticon-check-circle,
.ant-notification-notice-icon,
.ant-notification-notice-icon-success {
  margin-top: 5px !important;
}
