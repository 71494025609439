.text {
  color: var(--secondary-color);
}

.heading {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0px;
}

.no-territories {
  font-size: 12px !important;
  line-height: 20px !important;
  color: #001f45 !important;
  border: 1px solid var(--Neutrals-Neutrals200, #d3d3d3) !important;
  border-radius: 26px !important;
  background: #e6e6e6 !important;
  padding: 1px 6px !important;
  width: fit-content !important;
  height: 22px !important;
}
