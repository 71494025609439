.ant-upload-drag:hover {
  border-color: var(--secondary-color) !important;
}

.upload-hint-choose-file {
  color: #00a6fb;
  text-decoration: underline;
  font-size: 14px;
  font-family: Figtree !important;
  font-weight: 500;
}

.custom-dragger .ant-upload-list {
  display: none !important;
}

.uploaded-file-message {
  margin-top: 8px;
  color: #999;
  font-size: 12px;
}

.custom-dragger .ant-upload.ant-upload-btn {
  padding: 21px 0 20px 0 !important;
}

.custom-dragger .ant-upload.ant-upload-drag {
  border: none;
}

.custom-dragger .ant-upload.ant-upload-drag > .ant-upload.ant-upload-btn {
  border: 1px dashed #00a6fb;
  border-radius: 8px;
  background-color: #f1f8fe;
}

.custom-dragger .ant-upload-drag-icon {
  margin-bottom: 12px;
}
