.profile-upload .ant-modal-content {
  padding: 32px !important;
  width: 671px;
  height: 470px;
}

.profile-upload .ant-modal-title {
  color: #001f45;
  font-family: 'Figtree';
}

.profile-upload .ant-upload.ant-upload-drag > .ant-upload.ant-upload-btn {
  background-color: #f1f8fe;
  border: 1px dashed #00a6fb;
  border-radius: 8px;
}

.profile-upload .ant-upload-wrapper > .ant-upload.ant-upload-drag {
  height: 184px;
  width: 397px;
  border: none;
}

.profile-upload .ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 3px !important;
}
